import { useAuth0 } from "@auth0/auth0-react";
import {
    Save as SaveIcon,
    FilterAltOutlined as FilterAltOutlinedIcon,
    PushPinOutlined as PushPinIcon,
    RemoveCircleOutline as RemoveCircleOutlineIcon,
} from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    MenuItem,
    IconButton,
    PopoverPosition,
    Select,
    SelectChangeEvent,
    Stack,
    Tab,
    Tabs,
} from "@mui/material";
import {
    GridCellParams,
    GridCellSelectionModel,
    GridColDef,
    GridColumnMenuColumnsItem,
    GridColumnMenuFilterItem,
    GridColumnMenuGroupingItem,
    GridColumnMenuProps,
    GridPinnedColumnPosition,
    GridRowId,
    GridSortItem,
    GridToolbarContainer,
    useGridApiRef,
} from "@mui/x-data-grid-premium";
import React, { useCallback, useEffect, useState } from "react";
import ErrorMessage from "src/components/ErrorMessage/ErrorMessage";
import Loader from "src/components/Loader/Loader";
import {
    addressScheduleId,
    loginSchedule,
    scheduleSBPId,
    scheduleSBPIdLifePage1,
    scheduleSBPIdLifePage2,
} from "src/constants/Schedules";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { GraphqlService, WebLoginService, ProductService, ScheduleService, WebExtensionService } from "src/services";
import {
    returnTreeQuery,
    scheduleTemplateQuery,
} from "src/services/GQLQueries";
import GQLService from "src/services/GQLService";
import {
    getModuleId,
    getQuarterId,
    replaceUrlParam,
} from "src/services/Utility";
import {
    ColumnTemplateDTO,
    DataGridRow,
    FixMeLater,
    Jurisdiction,
    RowDTO,
    ScheduleRows,
    CellType,
    TabProperties,
    Tree,
    Company,
    Product,
    FolderNode,
    ReturnNode,
    ExtMessageResponse,
    ExtMessageResponseStatus,
    LoginData,
} from "src/types";
import Toolbar from "../Toolbar/Toolbar";
import "./DataTable.scss";
import { StyledCustomNoData, StyledDataGrid } from "./DataTable.styled";
import { EmptyState, Undraw } from "src/uikit";
import {
    convertSelectedCellsForExcel,
    convertSelectedCellsForExcelWithHeaders,
    convertSelectedRowsForExcel,
    convertSelectedRowsForExcelWithHeaders,
    selectAllRows,
} from "./CopyAndPaste.util";
import CustomSnackbar from "../CustomSnackbar/CustomSnackbar";
import DataTableContextMenu, {
    ContextMenuItem,
} from "./DataTableContextMenu/DataTableContextMenu";
import {
    checkDisplayOrderInRowDTOs,
    createDataGridColumns,
    createDataGridRows,
    customColumnHeader,
    isCellEditable,
    isSBPSchedule,
    isTwoPageSBPSchedule,
    renderCell,
} from "./DataTable.util";
import { Dayjs } from "dayjs";
import { StyledFlexContainer } from "../FolderNodeDialog/FolderNodeDialog.styled";
import PrintIcon from "@mui/icons-material/Print";
import LaunchIcon from "@mui/icons-material/Launch";

export type DataTableProps = {
    documentKey: string;
    isActive: boolean;
    company: Company;
    product: Product;
    folderNode: FolderNode;
    returnNode: ReturnNode;
    initJurisdiction?: number;
};

const DataTable: React.FC<DataTableProps> = ({
    documentKey,
    isActive,
    company,
    product,
    folderNode,
    returnNode,
    initJurisdiction = 1,
}) => {
    // Service Instances

    const scheduleService = ScheduleService.getInstance();
    const productService = ProductService.getInstance();
    const graphqlService = GraphqlService.getInstance();
    const webLoginService = WebLoginService.getInstance();
    const webExtensionService = WebExtensionService.getInstance();

    const { getAccessTokenSilently } = useAuth0();

    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const [confirmationMessage, setConfirmationMessage] = useState<string>("");
    const [dialogTitle, setDialogTitle] = useState("");

    // State

    const [columns, setColumns] = useState<GridColDef[]>([]);
    const [rows, setRows] = useState<DataGridRow[]>([]);
    const [rowDTOs, setRowDTOs] = useState<RowDTO[]>([]);
    // prettier-ignore
    const [columnTemplateDTOs, setColumnTemplateDTOs] = useState<ColumnTemplateDTO[]>([]);
    // prettier-ignore
    const [headerHeight, setHeaderHeight] = useState<number | undefined>(undefined);

    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState<string>("error");
    const [snackbarDuration, setSnackbarDuration] = useState<number>(3000);
    // prettier-ignore
    const [snackbarMessage, setSnackbarMessage] = useState<string | string[]>("");

    const [sortModel, setSortModel] = useState<GridSortItem[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<FixMeLater>(null);

    const [columnMap, setColumnMap] = useState<Map<string, number>>(new Map());
    const [rowMap, setRowMap] = useState<Map<number, number>>(new Map());
    // prettier-ignore
    const [updatedRows, setUpdatedRows] = useState<Set<number>>(new Set<number>());

    // prettier-ignore
    const [selectedJurisdiction, setSelectedJurisdiction] = useState<number>(initJurisdiction);
    // prettier-ignore
    const [jurisdictions, setJurisdictions] = useState<Map<number, Jurisdiction>>(new Map());

    const [selectedPage, setSelectedPage] = useState<number>(0);
    const [pendingChanges, setPendingChanges] = useState<boolean>(false);

    const [rowSelected, setRowSelected] = useState<GridRowId[]>([]);
    // prettier-ignore
    const [cellSelected, setCellSelected] = useState<GridCellSelectionModel>({});

    // prettier-ignore
    const [contextMenuPosition, setContextMenuPosition] = useState<PopoverPosition>({ top: 0, left: 0 });
    const [isPasteAvailable, setIsPasteAvailable] = useState(true);

    // Variables

    const apiRef = useGridApiRef();

    const isMenuOpen =
        contextMenuPosition.top !== 0 && contextMenuPosition.left !== 0;

    const showMenu =
        rowSelected.length > 0 || Object.keys(cellSelected).length > 0;

    // Redux

    const dispatch = useAppDispatch();

    const municipalState: string = useAppSelector(
        (state) => state?.Municipal?.value.selectedState,
    );
    const municipalQuarter: string = useAppSelector(
        (state) => state?.Municipal?.value.selectedQuarter,
    );
    const toogleFetch: boolean = useAppSelector(
        (state) => state?.[product?.productName]?.value?.toogleFetch,
    );

    const splitScreen = useAppSelector((state) => state?.Tabs?.splitScreen);

    const tabsProperties = useAppSelector(
        (state) => state?.Tabs,
    )?.tabsProperties;

    // useEffect hooks

    useEffect(() => {
        if (apiRef.current && Object.keys(apiRef.current).length) {
            const cols = apiRef.current.getAllColumns();
            const maxHeight = cols.reduce((max, column) => {
                const calcHeight =
                    (column.field.length * 10) / column.computedWidth;
                return Math.max(max, calcHeight);
            }, 0);
            if (maxHeight <= 1) {
                setHeaderHeight(undefined);
            } else {
                setHeaderHeight(120);
            }
        }
    }, [apiRef, columns]);

    useEffect(() => {
        if (tabsProperties[documentKey]) {
            const updatedTabProperties: TabProperties = {
                ...tabsProperties[documentKey],
                isActive: isActive,
            };
            dispatch(GlobalStateActions.setTab(updatedTabProperties));
        }

        if (isActive) {
            replaceUrlParam("year", product?.taxYear);
            replaceUrlParam("companyId", company?.id);
            replaceUrlParam("folderNodeId", folderNode?.id);
            replaceUrlParam("returnNodeId", returnNode?.id);

            if (product?.productName === "Municipal") {
                replaceUrlParam("selectedState", municipalState);
                replaceUrlParam("selectedQuarter", municipalQuarter);
            }
        }
    }, [isActive]);

    useEffect(() => {
        const fillDataGrid = async () => {
            try {
                setIsLoading(true);
                let updatedColumns = createDataGridColumns(
                    columnTemplateDTOs,
                    rowDTOs,
                    renderCell,
                    handleDropDownChange,
                    handleCheckboxChange,
                    handleDateChange,
                    customColumnHeader,
                    apiRef,
                    columnMap,
                    sortModel,
                    setSortModel,
                );

                if (returnNode.id == loginSchedule) {
                    updatedColumns =
                        createColumnsWithGoButtonForLoginSchedule(
                            updatedColumns,
                        );
                }
                const updatedRows = createDataGridRows(
                    columnTemplateDTOs,
                    rowDTOs,
                    returnNode,
                );
                setColumns(updatedColumns);
                setRows(updatedRows);
                setError(null);
            } catch (error: FixMeLater) {
                console.error("Error fetching schedule:", error);
                setError(error);
            } finally {
                setIsLoading(false);
            }
        };
        if (columnTemplateDTOs?.length && rowDTOs?.length) {
            fillDataGrid();
        }
    }, [columnTemplateDTOs, rowDTOs, sortModel]);

    useEffect(() => {
        const loadSBPData = async () => {
            if (isSBPSchedule(returnNode)) {
                let jurisdictionMap = jurisdictions;
                if (!jurisdictions.size) {
                    const response: Jurisdiction[] =
                        await productService.getProductJurisdictions(
                            product.productId,
                        );
                    response.push({
                        id: 99,
                        longName: "All States",
                        abbrev: "All",
                    });
                    jurisdictionMap = response.reduce(
                        (map, jurisdiction: Jurisdiction) => {
                            map.set(jurisdiction.id, jurisdiction);
                            return map;
                        },
                        new Map<number, Jurisdiction>(),
                    );
                    setJurisdictions(jurisdictionMap);
                }
                if (!jurisdictionMap.get(selectedJurisdiction)) {
                    setSelectedJurisdiction(
                        jurisdictionMap.entries().next().value[0],
                    );
                    setSelectedPage(0);
                } else {
                    fetchData();
                }
            } else {
                setSelectedJurisdiction(0);
            }
        };

        const fetchData = async () => {
            try {
                const currentScheduleId =
                    returnNode.id === scheduleSBPIdLifePage1 &&
                    selectedPage === 1
                        ? scheduleSBPIdLifePage2
                        : returnNode.id;
                setIsLoading(true);
                const scheduleInput = {
                    companyId: company.id,
                    folderId: folderNode.id,
                    moduleId: getModuleId(product, company, municipalState),
                    pageNumber: "0",
                    productId: product?.productId.toString(),
                    returnId: currentScheduleId,
                    taxYearId: product?.taxYear.toString(),
                    quarterId: getQuarterId(municipalQuarter),
                };
                const accessToken = await getAccessTokenSilently();
                const {
                    scheduleTemplateDTO: { columnTemplateDTOs, rowDTOs },
                }: FixMeLater = await GQLService.fetchGraphQLData(
                    scheduleTemplateQuery,
                    { scheduleInput, queryJurisdiction: selectedJurisdiction },
                    accessToken,
                );
                const updatedColumnMap = columnTemplateDTOs.reduce(
                    (map, column, index) => {
                        map.set(column.columnTemplate.name, index);
                        return map;
                    },
                    new Map<string, number>(),
                );
                const canUseDisplayOrder = checkDisplayOrderInRowDTOs(rowDTOs);
                const updatedRowMap = rowDTOs.reduce(
                    (map, row: RowDTO, index) => {
                        map.set(
                            canUseDisplayOrder ? row.displayOrder : row.rowId,
                            index,
                        );
                        return map;
                    },
                    new Map<string, number>(),
                );
                setRowDTOs([...rowDTOs]);
                setColumnTemplateDTOs(columnTemplateDTOs);
                setRowMap(updatedRowMap);
                setColumnMap(updatedColumnMap);

                // display loading is required until fillDataGrid will be completed
                // need to stop loading only in case of empty rows received
                if (!rowDTOs?.length) {
                    setIsLoading(false);
                }
            } catch (error: FixMeLater) {
                console.error("Error fetching return template:", error);
                setError(error);
            }
        };
        updatedRows.clear();
        setUpdatedRows(new Set([...updatedRows]));
        setPendingChanges(false);
        dispatch(GlobalStateActions.removePendingChange(documentKey));
        loadSBPData();
        if (!isSBPSchedule(returnNode)) {
            if (selectedJurisdiction === 0) {
                fetchData();
            }
        } else {
            if (jurisdictions.get(selectedJurisdiction)) {
                fetchData();
            }
        }
    }, [
        folderNode,
        returnNode,
        selectedJurisdiction,
        selectedPage,
        toogleFetch,
    ]);

    // Handlers and other functions

    const handleSnackbar = (
        message: string | string[],
        severity: string,
        autoHideDuration: number = 3000,
    ) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
        setSnackbarDuration(autoHideDuration);
    };

    const handleCloseMenu = () => setContextMenuPosition({ top: 0, left: 0 });

    const handleContextMenu = (event) => {
        event.preventDefault();
        setContextMenuPosition({
            top: event.clientY - 4,
            left: event.clientX - 2,
        });
    };

    const handleCopy = () => {
        let clipboardText = "";
        if (rowSelected.length > 0) {
            clipboardText = convertSelectedRowsForExcel(
                rowSelected,
                rowDTOs,
                returnNode,
            );
        } else {
            clipboardText = convertSelectedCellsForExcel(cellSelected, apiRef);
        }
        navigator.clipboard.writeText(clipboardText).then(() => {
            handleCloseMenu();
        });
    };

    const handleCopyWithHeaders = () => {
        let clipboardText = "";
        if (rowSelected.length > 0) {
            clipboardText = convertSelectedRowsForExcelWithHeaders(
                rowSelected,
                columns,
                rowDTOs,
                returnNode,
            );
        } else {
            clipboardText = convertSelectedCellsForExcelWithHeaders(
                cellSelected,
                columns,
                apiRef,
            );
        }
        navigator.clipboard.writeText(clipboardText).then(() => {
            handleCloseMenu();
        });
    };

    const handleCellClick = useCallback(
        (params: GridCellParams) => {
            apiRef.current.setRowSelectionModel([]);
            if (params.field === columns[0].headerName) {
                apiRef.current.setRowSelectionModel([params.id]);
            }
        },
        [apiRef, rows, columns],
    );

    const handlePaste = async () => {
        let clipboardText = "";
        try {
            const clipboardText = await navigator.clipboard.readText();
            setIsPasteAvailable(!!clipboardText);
        } catch (error) {
            setIsPasteAvailable(false);
            console.error(error);
            const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;

            // Customize the error message based on the OS
            const errorMessage = `The paste function is not available in the browser. Use ${
                isMac ? "Command+V" : "Control+V"
            } instead`;
            handleSnackbar(errorMessage, "error");
            return;
        } finally {
            handleCloseMenu();
        }

        try {
            clipboardText = await navigator.clipboard.readText();
            const rowsToPaste = clipboardText
                .split("\n")
                .map((row) => row.split("\t").map((cell) => cell.trim()));

            const updatedRows: DataGridRow[] = [...rows];
            const previousRows: DataGridRow[] = [...rows];

            let startRowIdx = 0;
            let startColIdx = 0;

            if (Object.keys(cellSelected).length > 0) {
                const selectedRowId = Object.keys(cellSelected)[0];
                const selectedColumnId = Object.keys(
                    cellSelected[selectedRowId],
                )[0];
                startRowIdx =
                    rows.findIndex((row) => String(row.id) === selectedRowId) ??
                    0;

                startColIdx =
                    columns.findIndex(
                        (col) => col.headerName === selectedColumnId,
                    ) ?? 0;
            }

            rowsToPaste.forEach((row, rowIdx) => {
                const targetRowIdx = startRowIdx + rowIdx;
                if (targetRowIdx < updatedRows.length) {
                    const updatedRow = { ...updatedRows[targetRowIdx] };
                    row.forEach((cellValue, colIdx) => {
                        const targetColIdx = startColIdx + colIdx;
                        if (
                            targetColIdx >= 0 &&
                            targetColIdx < columns.length
                        ) {
                            const targetColField = columns[targetColIdx].field;
                            if (targetColField) {
                                const isReturnOrNewLine =
                                    cellValue.includes("\n") ||
                                    cellValue.includes("\r");
                                updatedRow[targetColField] = isReturnOrNewLine
                                    ? ""
                                    : cellValue;
                            }
                        }
                    });

                    updatedRows[targetRowIdx] = updatedRow;
                    processRowUpdate(updatedRow, previousRows[targetRowIdx]);
                }
            });
        } catch (error) {
            console.error(error);
            handleSnackbar(`There has been an error . ${error}`, "error");
        } finally {
            handleCloseMenu();
        }
    };

    const onSaveClick = async () => {
        try {
            setIsLoading(true);
            setPendingChanges(false);
            dispatch(GlobalStateActions.removePendingChange(documentKey));
            dispatch(GlobalStateActions.setPendingChange(`${documentKey}*`));
            await updateSchedule();
            updatedRows.clear();
            setUpdatedRows(new Set([...updatedRows]));
            dispatch(GlobalStateActions.removePendingChange(`${documentKey}*`));
            setIsLoading(false);

            if (
                product?.productName === "Municipal" &&
                returnNode?.displayName.includes("Input Sch Qtr")
            ) {
                const treeInput = {
                    companyId: company?.id,
                    productId: product?.productId,
                    taxYearId: product?.taxYear,
                    moduleId: getModuleId(product, company, municipalState),
                };

                const { tree } = await graphqlService.fetchData<{ tree: Tree }>(
                    returnTreeQuery,
                    { treeInput },
                );

                dispatch(
                    GlobalStateActions[product?.productName].setTree(tree),
                );

                handleSnackbar(
                    "New municipal returns have been automatically activated.",
                    "success",
                );
            }

            // To update the other open return document or schedule if split screen is enabled
            // set the return or schedule just got updated to true
            // so that the other return document will be re-rendered and set it to false again
            if (splitScreen) {
                dispatch(
                    GlobalStateActions.setReturnOrScheduleJustGotUpdated(true),
                );
            }
        } catch (e) {
            handleSnackbar(
                "There was an error when trying to save the Document.",
                "error",
            );
        }
    };

    // Toolbar icon data here
    const iconData = [
        {
            position: 1,
            icon: SaveIcon,
            title: "Save",
            onClick: onSaveClick,
            isEnabled: true,
            isActive: pendingChanges,
            isValid: true,
        },
        // {
        //     position: 2,
        //     icon: PrintIcon,
        //     title: "Print",
        //     onClick: () => handlePrintExport(),
        //     isEnabled: true,
        //     isActive: true,
        //     isValid: true,
        // },
    ];

    const findUpdatedColumnNames = (
        newRow: DataGridRow,
        oldRow: DataGridRow,
    ): string[] => {
        const updatedKeys: string[] = [];
        for (const key in newRow) {
            if (newRow[key] !== oldRow[key]) {
                updatedKeys.push(key);
            }
        }
        return updatedKeys;
    };

    const handlePageChange = (event: React.SyntheticEvent, page: number) => {
        if (pendingChanges) {
            const userConfirmed = window.confirm(
                "Changes you made may not be saved.",
            );
            if (!userConfirmed) {
                return;
            }
        }
        setSelectedPage(page);
    };

    const handleSelectJurisdicion = (e: SelectChangeEvent<number>) => {
        if (pendingChanges) {
            const userConfirmed = window.confirm(
                "Changes you made may not be saved.",
            );
            if (!userConfirmed) {
                return;
            }
        }
        setSelectedJurisdiction(Number(e.target.value));
        dispatch(
            GlobalStateActions.Premium.setSelectedJurisdictionForSBP(
                Number(e.target.value),
            ),
        );
    };
    const handleDateChange = (
        newValue: Dayjs | null,
        rowId: number,
        columnName: string,
    ) => {
        const rowIndex = rowMap.get(rowId);
        if (rowIndex != undefined) {
            const selectedRow = rowDTOs[rowIndex];
            const selectedColumnIndex = columnMap.get(columnName);
            if (selectedColumnIndex !== undefined) {
                const cell =
                    selectedRow.cellGroups[selectedRow.currentIndex].cells[
                        selectedColumnIndex
                    ];
                cell.ov = newValue ? newValue.format("YYYY-MM-DD") : "";
                setRowDTOs([...rowDTOs]);
                updatedRows.add(rowIndex);
                setUpdatedRows(new Set([...updatedRows]));
                setPendingChanges(true);
                dispatch(GlobalStateActions.setPendingChange(documentKey));
            }
        }
    };

    const handleDropDownChange = (e: SelectChangeEvent, rowId: number) => {
        const rowIndex = rowMap.get(rowId);
        if (rowIndex != undefined) {
            const selectedRow = rowDTOs[rowIndex];
            selectedRow.currentIndex = Number(e.target.value);
            setRowDTOs([...rowDTOs]);
            updatedRows.add(rowIndex);
            setUpdatedRows(new Set([...updatedRows]));
            setPendingChanges(true);
            dispatch(GlobalStateActions.setPendingChange(documentKey));
        }
    };

    const handleCheckboxChange = (rowId: number, columnName: string) => {
        const rowIndex = rowMap.get(rowId);
        if (rowIndex != undefined) {
            const selectedRow = rowDTOs[rowIndex];
            const selectedColumnIndex = columnMap.get(columnName);
            if (selectedColumnIndex !== undefined) {
                const cell =
                    selectedRow.cellGroups[
                        returnNode.id !== addressScheduleId
                            ? 0
                            : selectedRow.currentIndex
                    ].cells[selectedColumnIndex];
                const currentValue =
                    cell.ov !== undefined && cell.ov !== "" ? cell.ov : cell.v;
                cell.ov = (currentValue !== "true").toString();
                setRowDTOs([...rowDTOs]);
                updatedRows.add(rowIndex);
                setUpdatedRows(new Set([...updatedRows]));
                setPendingChanges(true);
                dispatch(GlobalStateActions.setPendingChange(documentKey));
            }
        }
    };

    const processRowUpdate = (newRow, oldRow) => {
        const updatedColumnNames = findUpdatedColumnNames(newRow, oldRow);
        let isError = false;

        if (updatedColumnNames.length > 0) {
            updatedColumnNames.forEach((updatedColumnName: string) => {
                const rowIndex = rowMap.get(newRow.id);
                const selectedColumnIndex = columnMap.get(updatedColumnName);
                if (selectedColumnIndex && rowIndex != undefined) {
                    const selectedRow = rowDTOs[rowIndex];
                    const cell =
                        selectedRow.cellGroups[
                            returnNode.id !== addressScheduleId
                                ? 0
                                : selectedRow.currentIndex
                        ].cells[selectedColumnIndex];

                    if (cell.cellType === CellType.NUMBER) {
                        let value = newRow[updatedColumnName];
                        // Remove commas from the value
                        value = value.toString().replace(/,/g, "");
                        if (isNaN(value)) {
                            isError = true;
                            setDialogTitle("Oops! Invalid Number Input");
                            setConfirmationMessage(
                                "This field only accepts numeric values. Please enter a valid number.",
                            );
                            setShowConfirmation(true);
                            newRow[updatedColumnName] =
                                oldRow[updatedColumnName];
                        } else {
                            // Update the value without commas
                            newRow[updatedColumnName] = value;
                        }
                    }
                    if (!cell.editable) {
                        setDialogTitle("Oops! Field is Non-Editable");
                        setConfirmationMessage(
                            "This field is non-editable. Please paste data into an editable field.",
                        );
                        setShowConfirmation(true);
                        newRow[updatedColumnName] = oldRow[updatedColumnName];
                    }

                    const value =
                        cell.cellType !== CellType.DATE
                            ? newRow[updatedColumnName]
                            : oldRow[updatedColumnName];
                    cell.ov = cell.v !== value ? value : "";
                    setRowDTOs([...rowDTOs]);
                    updatedRows.add(rowIndex);
                    setUpdatedRows(new Set([...updatedRows]));
                    setPendingChanges(true);
                    dispatch(GlobalStateActions.setPendingChange(documentKey));
                }
            });
        }
        return {
            ...newRow,
        };
    };

    const handleCloseConfirmation = () => {
        setShowConfirmation(false); // Close the dialog
    };

    const updateSchedule = async () => {
        const rowsToBeUpdated: RowDTO[] = Array.from(updatedRows).map(
            (index) => {
                const row = rowDTOs[index];
                return {
                    ...rowDTOs[index],
                    currentIndex:
                        returnNode.id !== addressScheduleId
                            ? 0
                            : row.currentIndex,
                };
            },
        );
        const currentScheduleId =
            returnNode.id === scheduleSBPIdLifePage1 && selectedPage === 1
                ? scheduleSBPIdLifePage2
                : returnNode.id;
        const scheduleInput: ScheduleRows = {
            queryJurisdiction: isSBPSchedule(returnNode)
                ? Number(selectedJurisdiction)
                : undefined,
            scheduleKey: {
                companyId: company.id,
                moduleId: Number(getModuleId(product, company, municipalState)),
                productId: product?.productId,
                scheduleId: Number(currentScheduleId),
                taxYear: product?.taxYear,
                quarterId: getQuarterId(municipalQuarter),
            },
            rowDTOs: rowsToBeUpdated,
        };
        const result = await scheduleService.rowsUpdate(scheduleInput);
        const canUseDisplayOrder = checkDisplayOrderInRowDTOs(result.rowDTOs);
        result.rowDTOs.forEach((row) => {
            const rowIndex = rowMap.get(
                canUseDisplayOrder ? row.displayOrder : row.rowId,
            );
            if (rowIndex != undefined) {
                rowDTOs[rowIndex] = row;
            }
        });
        setRowDTOs([...rowDTOs]);
    };

    const CustomColumnMenu = (props: GridColumnMenuProps) => {
        const itemProps = {
            colDef: props.colDef,
            onClick: props.hideMenu,
        };

        const pinToLeft = () => {
            apiRef.current.pinColumn(
                props.colDef.field,
                GridPinnedColumnPosition.LEFT,
            );
        };

        const unPin = () => {
            apiRef.current.unpinColumn(props.colDef.field);
        };

        const isPinned: GridPinnedColumnPosition | boolean =
            apiRef.current.isColumnPinned(props.colDef.field);

        return (
            <>
                <Stack px={0.5} py={0.5}>
                    <MenuItem
                        onClick={() => {
                            isPinned ? unPin() : pinToLeft();
                        }}
                    >
                        {isPinned ? (
                            <RemoveCircleOutlineIcon
                                sx={{
                                    marginRight: "12px",
                                    fill: "rgba(0, 0, 0, 0.54)",
                                }}
                            />
                        ) : (
                            <PushPinIcon
                                sx={{
                                    marginRight: "12px",
                                    fill: "rgba(0, 0, 0, 0.54)",
                                }}
                            />
                        )}{" "}
                        {isPinned ? "Unpin" : "Pin"}
                    </MenuItem>
                    <GridColumnMenuFilterItem {...itemProps} />
                    <GridColumnMenuGroupingItem {...itemProps} />
                </Stack>
                <Divider />
                <Stack px={0.5} py={0.5}>
                    <GridColumnMenuColumnsItem {...itemProps} />
                </Stack>
            </>
        );
    };

    const handlePrintExport = () => {
        // Access the columns and rows from the DataGrid
        const columns = apiRef.current
            .getAllColumns()
            .filter(
                (col) =>
                    col.field !== "__check__" &&
                    col.field !== "__detail_panel__",
            ); // Filter out non-data columns
        const rows = apiRef.current.getRowModels();

        // Define max rows and columns per page
        const maxColumnsPerPage = 6; // Adjust this number based on your desired columns per page
        const maxRowsPerPage = 32; // Adjust this number based on your desired rows per page

        // Calculate the number of row sets and pages per row set
        const totalRowSets = Math.ceil(rows.size / maxRowsPerPage);
        const totalPagesPerRowSet = Math.ceil(
            columns.length / maxColumnsPerPage,
        );

        // Create the HTML for the table, split into multiple pages if necessary
        let tableHtml = `
            <html>
            <head>
                <title>${documentKey}</title>
                <style>
                    /* Add custom styles here */
                    body { font-family: Arial, sans-serif; padding: 32px }
                    table { width: 100%; border-collapse: collapse; }
                    th, td { border: 1px solid #ddd; padding: 8px; }
                    td { text-align: left; }
                    th { background-color: #f2f2f2; text-align: left; }
                    tr { height: 36px; }
                    @media print {
                        table { page-break-inside: avoid; }
                    }
                </style>
            </head>
            <body>
        `;

        for (let rowSetIndex = 0; rowSetIndex < totalRowSets; rowSetIndex++) {
            const startRow = rowSetIndex * maxRowsPerPage;
            const endRow = startRow + maxRowsPerPage;
            const rowsForPage = Array.from(rows.entries()).slice(
                startRow,
                endRow,
            );

            for (
                let pageIndex = 0;
                pageIndex < totalPagesPerRowSet;
                pageIndex++
            ) {
                const startCol = pageIndex * maxColumnsPerPage;
                const endCol = startCol + maxColumnsPerPage;
                const columnsForPage = columns.slice(startCol, endCol);

                tableHtml += `
                    <table>
                        <thead>
                            <tr>
                                ${columnsForPage.map((col) => `<th>${col.headerName}</th>`).join("")}
                            </tr>
                        </thead>
                        <tbody>
                            ${rowsForPage
                                .map(
                                    ([id, row]) => `
                                    <tr>
                                        ${columnsForPage.map((col) => `<td>${row[col.field]}</td>`).join("")}
                                    </tr>
                                `,
                                )
                                .join("")}
                        </tbody>
                    </table>
                `;

                // Add a page break only if it's not the last page
                if (
                    pageIndex < totalPagesPerRowSet - 1 ||
                    rowSetIndex < totalRowSets - 1
                ) {
                    tableHtml += '<div style="page-break-after:always;"></div>';
                }
            }
        }

        tableHtml += `
            </body>
            </html>
        `;

        // Open a new window and write the table HTML
        const newWindow = window.open(
            "Exported From Stratus",
            "_blank",
            "width=800,height=600",
        );

        if (!newWindow) {
            handleSnackbar(
                "Please enable pop-ups for this site to print.",
                "error",
            );
            return;
        }

        newWindow.document.write(tableHtml);
        newWindow.document.title = `${documentKey}.pdf`;
        newWindow.document.close();

        // Trigger the print dialog
        newWindow.focus();
        newWindow.print();
        newWindow.close();
    };

    const createColumnsWithGoButtonForLoginSchedule = (
        existingColumns: GridColDef[],
    ): GridColDef[] => {
        if (existingColumns.length === 0) {
            return existingColumns;
        }
        const lastColumn = { ...existingColumns[existingColumns.length - 1] };

        lastColumn.field = "go";
        lastColumn.headerName = "Go";
        lastColumn.width = 20;
        lastColumn.sortable = false;
        lastColumn.editable = false;
        lastColumn.renderCell = (params) => {
            return <IconButton
            aria-label="go"
            onClick={() => {
                if(pendingChanges) {
                    handleSnackbar("Please save changes before logging in", "warning");
                    return;
                }
                openLogin(params.row)
            }}
        >
            <LaunchIcon />
        </IconButton>
        };

        return [...existingColumns, lastColumn];
    };

    const openLogin = async (row: DataGridRow): Promise<void> => {

        const message = `Web login for ${returnNode?.displayName}`;

        const loginData: LoginData = await webLoginService.getLoginDataByRowId(row);
        
        handleSnackbar(message, "info", 1000);

        const loginResponseData: ExtMessageResponse = await webExtensionService.doLogin(loginData);

        if (loginResponseData.status === ExtMessageResponseStatus.ERROR) {
            handleSnackbar(message + " failed : "+loginResponseData.message , "error");
            return;
        }

        if (loginResponseData.status === ExtMessageResponseStatus.SUCCESS) {
            handleSnackbar(message + " completed", "success");
            return;
        }

        if (loginResponseData.status === ExtMessageResponseStatus.WARNING) {
            handleSnackbar(message + " completed with warnings", "warning");
            return;
        }
    }


    // Replace GridToolbarExport with a custom print button in your customToolbar
    const customToolbar = () => {
        return (
            <GridToolbarContainer className="grid-toolbar-container">
                <div className="first-grid-toolbar-row">
                    {isTwoPageSBPSchedule(returnNode) ? (
                        <Tabs value={selectedPage} onChange={handlePageChange}>
                            <Tab label="Page 1" value={0} />
                            <Tab label="Page 2" value={1} />
                        </Tabs>
                    ) : (
                        <></>
                    )}
                    {isSBPSchedule(returnNode) &&
                    jurisdictions.get(selectedJurisdiction) ? (
                        <Select
                            className="sbp-jurisdiction-select"
                            key={`sbp-jurisdiction-select`}
                            value={selectedJurisdiction}
                            onChange={(e) => handleSelectJurisdicion(e)}
                            autoWidth={false}
                        >
                            {Array.from(jurisdictions.entries()).map(
                                ([key, jurisdiction]) => (
                                    <MenuItem
                                        key={`jurisdiction${jurisdiction.id}`}
                                        value={key}
                                    >
                                        {jurisdiction.longName}
                                    </MenuItem>
                                ),
                            )}
                        </Select>
                    ) : (
                        <></>
                    )}
                </div>
            </GridToolbarContainer>
        );
    };

    const customNoData = () => {
        return (
            <StyledCustomNoData data-testid="custom-no-data-component">
                <EmptyState
                    graphic={<Undraw fileName="NoData.svg" height={100} />}
                    title="No Data Found"
                    text="The table currently has no data to display. Please check back later for any new updates or additions."
                    actions={[]}
                />
            </StyledCustomNoData>
        );
    };

    const SelectAllRowsButton = () => {
        return (
            <div
                className="select-all-rows-button-container"
                style={{ top: isSBPSchedule(returnNode) ? "53px" : "6px" }}
                onClick={() => selectAllRows(apiRef)}
            >
                <div className="select-all-rows-button" />
            </div>
        );
    };

    // Render Logic
    const contextMenuItems: ContextMenuItem[] = [
        {
            label: "Copy",
            onClick: handleCopy,
        },
        {
            label: "Copy with Headers",
            onClick: handleCopyWithHeaders,
        },
        {
            label: "Paste",
            onClick: handlePaste,
            disabled: !isPasteAvailable,
        },
    ];

    if (error)
        return (
            <ErrorMessage
                error={`Error fetching the pdf: ${error.toString()}`}
            />
        );
    if (!error && isLoading)
        return (
            <>
                <Loader />
                <CustomSnackbar
                    open={snackbarOpen}
                    setOpen={setSnackbarOpen}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                    autoHideDuration={snackbarDuration}
                />
            </>
        );

    return (
        <div className="data-table-container" onContextMenu={handleContextMenu}>
            <div className="grid-container">
                <StyledDataGrid
                    hideFooter
                    density="compact"
                    processRowUpdate={processRowUpdate}
                    columns={columns}
                    rows={rows}
                    slots={{
                        toolbar: customToolbar,
                        noRowsOverlay: customNoData,
                        columnMenuIcon: () => <></>,
                        columnMenuFilterIcon: () => <FilterAltOutlinedIcon />,
                        columnMenu: (props) => CustomColumnMenu(props),
                    }}
                    sortModel={sortModel}
                    columnHeaderHeight={headerHeight}
                    isCellEditable={isCellEditable}
                    onCellClick={handleCellClick}
                    onCellSelectionModelChange={(newCellSelectionModel) => {
                        setCellSelected(newCellSelectionModel);
                    }}
                    disableRowSelectionOnClick
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setRowSelected(newRowSelectionModel);
                    }}
                    ignoreValueFormatterDuringExport
                    apiRef={apiRef}
                    groupingColDef={{
                        renderHeader: (params) =>
                            customColumnHeader(
                                params,
                                true,
                                apiRef,
                                sortModel,
                                setSortModel,
                            ),
                    }}
                    cellSelection
                    disableMultipleRowSelection
                    rowSelection={false}
                    onBeforeClipboardPasteStart={async () => {
                        handlePaste();
                        throw new Error("Paste handled"); // This will prevent the default paste behavior from Mui
                    }}
                />
                <SelectAllRowsButton />
            </div>
            {showMenu && (
                <DataTableContextMenu
                    open={isMenuOpen}
                    position={contextMenuPosition}
                    items={contextMenuItems}
                    onClose={handleCloseMenu}
                />
            )}
            <div className="grid-right-toolbar-container">
                <Toolbar items={iconData} />
            </div>
            <CustomSnackbar
                open={snackbarOpen}
                setOpen={setSnackbarOpen}
                message={snackbarMessage}
                severity={snackbarSeverity}
                autoHideDuration={snackbarDuration}
            />
            <Dialog open={showConfirmation} onClose={handleCloseConfirmation}>
                <DialogTitle
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <InfoIcon
                        color="info"
                        style={{ marginRight: 20 }}
                        fontSize="medium"
                    />
                    {dialogTitle}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>{confirmationMessage}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <StyledFlexContainer justify={"center"}>
                        <Button
                            onClick={handleCloseConfirmation}
                            color="primary"
                        >
                            OK
                        </Button>
                    </StyledFlexContainer>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default DataTable;
