import React, { FC, useState } from "react";
import "./CompanyOptions.scss";
import { useDispatch } from "react-redux";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { Product } from "src/types";
import { useAppSelector } from "src/hooks";
import {
    FormControlLabel,
    Switch,
    Box,
    IconButton,
    Typography,
    Tabs,
    Tab,
    Select,
    MenuItem,
    FormControl,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { USStateAbbreviations } from "src/constants/States";
import { DataGridPremium, GridColDef } from "@mui/x-data-grid-premium";
import { SelectChangeEvent } from "@mui/material";

const CompanyOptions: FC = () => {
    const dispatch = useDispatch();

    const product: Product | undefined = useAppSelector(
        (state) => state?.Product?.value,
    );

    const [formData, setFormData] = useState({
        coloradoRHOQualification: false, // Initialize for Product tab
        newJerseyPremiumCalculationMethod: false, // Initialize for Product tab
        ...USStateAbbreviations.reduce(
            (acc, state) => ({
                ...acc,
                [state]: {
                    dollarsAndCentsDisplay: false,
                    defaultOverpaymentHandling: false,
                    dividendOptions: false,
                    printOptions: false,
                    municipalOptions: false,
                    retaliatoryReturnsDisplay: "both", // Initialize with a default value for dropdown
                },
            }),
            {},
        ),
    });

    const [activeTab, setActiveTab] = useState(0);

    if (!product) return <div>Please select a product to continue!</div>;

    // Handler for individual state-based switches
    const handleStateToggleChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        state: string,
        option: string,
    ) => {
        setFormData({
            ...formData,
            [state]: {
                ...formData[state],
                [option]: event.target.checked,
            },
        });
    };

    // Handler for product-level switches
    const handleProductToggleChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const { name, checked } = event.target;
        setFormData({
            ...formData,
            [name]: checked,
        });
    };

    // Handler for state-based dropdown (retaliatory returns)
    const handleDropdownChange = (event: SelectChangeEvent, state: string) => {
        setFormData({
            ...formData,
            [state]: {
                ...formData[state],
                retaliatoryReturnsDisplay: event.target.value as string,
            },
        });
    };

    const handleCloseCompanyOptions = () => {
        dispatch(
            GlobalStateActions[product?.productName]?.setCompanyOptionsOpen(
                false,
            ),
        );
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    };

    // DataGrid columns definition
    const columns: GridColDef[] = [
        { field: "state", headerName: "State", width: 150 },
        {
            field: "dollarsAndCentsDisplay",
            headerName: "Dollars and Cents Display",
            renderCell: (params) => (
                <Switch
                    checked={params.value}
                    onChange={(event) =>
                        handleStateToggleChange(
                            event,
                            params.row.state,
                            "dollarsAndCentsDisplay",
                        )
                    }
                />
            ),
            width: 250,
        },
        {
            field: "defaultOverpaymentHandling",
            headerName: "Default Overpayment Handling",
            renderCell: (params) => (
                <Switch
                    checked={params.value}
                    onChange={(event) =>
                        handleStateToggleChange(
                            event,
                            params.row.state,
                            "defaultOverpaymentHandling",
                        )
                    }
                />
            ),
            width: 250,
        },
        {
            field: "dividendOptions",
            headerName: "Dividend Options",
            renderCell: (params) => (
                <Switch
                    checked={params.value}
                    onChange={(event) =>
                        handleStateToggleChange(
                            event,
                            params.row.state,
                            "dividendOptions",
                        )
                    }
                />
            ),
            width: 200,
        },
        {
            field: "printOptions",
            headerName: "Print Options",
            renderCell: (params) => (
                <Switch
                    checked={params.value}
                    onChange={(event) =>
                        handleStateToggleChange(
                            event,
                            params.row.state,
                            "printOptions",
                        )
                    }
                />
            ),
            width: 200,
        },
        {
            field: "municipalOptions",
            headerName: "Municipal Options",
            renderCell: (params) => (
                <Switch
                    checked={params.value}
                    onChange={(event) =>
                        handleStateToggleChange(
                            event,
                            params.row.state,
                            "municipalOptions",
                        )
                    }
                />
            ),
            width: 200,
        },
        {
            field: "retaliatoryReturnsDisplay",
            headerName: "Retaliatory Returns Display",
            renderCell: (params) => (
                <FormControl fullWidth>
                    <Select
                        value={params.value}
                        onChange={(event) =>
                            handleDropdownChange(event, params.row.state)
                        }
                    >
                        <MenuItem value="both">
                            Display both Returns and Worksheets
                        </MenuItem>
                        <MenuItem value="returnsOnly">
                            Display Returns only
                        </MenuItem>
                        <MenuItem value="worksheetsOnly">
                            Display Worksheets only
                        </MenuItem>
                    </Select>
                </FormControl>
            ),
            width: 300,
        },
    ];

    // Prepare rows for DataGrid
    const rows = USStateAbbreviations.map((state, index) => ({
        id: index,
        state,
        ...formData[state],
    }));

    return (
        <div className="company-options-container">
            <IconButton
                className="close-button"
                onClick={handleCloseCompanyOptions}
            >
                <CloseIcon />
            </IconButton>
            <Typography variant="h6" className="header">
                {product?.productName} Options
            </Typography>
            <div className="tabs-section">
                <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    aria-label="company options tabs"
                >
                    <Tab label="Company" />
                    <Tab label="Jurisdictions" />
                </Tabs>
            </div>
            <Box className="toggle-section">
                {activeTab === 0 && (
                    <>
                        {/* Product Tab content */}
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={formData.coloradoRHOQualification}
                                    onChange={handleProductToggleChange}
                                    name="coloradoRHOQualification"
                                />
                            }
                            label="Qualified for RHO in CO"
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={
                                        formData.newJerseyPremiumCalculationMethod
                                    }
                                    onChange={handleProductToggleChange}
                                    name="newJerseyPremiumCalculationMethod"
                                />
                            }
                            label="Calc NJ Premiums according to N.J.S.A. 54:18A-6"
                        />
                    </>
                )}
                {activeTab === 1 && (
                    <>
                        {/* Jurisdictions Tab content */}
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                width: "90%",
                            }}
                        >
                            <DataGridPremium
                                rows={rows}
                                columns={columns}
                                checkboxSelection={false}
                                disableRowSelectionOnClick
                                autoHeight
                            />
                        </Box>
                    </>
                )}
            </Box>
        </div>
    );
};

export default CompanyOptions;
