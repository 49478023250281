import React, { FC } from "react";
import "./CompanyOptionsMenu.scss";
import { FolderNode as FolderNodeType, Product } from "src/types";
import { Menu, MenuItem } from "@mui/material";
import { useDispatch } from "react-redux";
import { useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";

type CompanyOptionsMenuProps = {
    anchorEl: null | HTMLElement;
    onCloseCompanyOptionsMenu: () => void;
    folderNode: FolderNodeType;
};

const CompanyOptionsMenu: FC<CompanyOptionsMenuProps> = ({
    anchorEl,
    onCloseCompanyOptionsMenu,
    folderNode,
}) => {
    const dispatch = useDispatch();

    const product: Product | undefined = useAppSelector(
        (state) => state?.Product?.value,
    );

    if (!product) return <div>Please select a product to continue!</div>;

    return (
        <div>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={onCloseCompanyOptionsMenu}
                TransitionProps={{
                    timeout: 0,
                }}
            >
                <MenuItem
                    onClick={() => {
                        dispatch(
                            GlobalStateActions[
                                product?.productName
                            ]?.setCompanyOptionsOpen(true),
                        );
                    }}
                >
                    Company Options
                </MenuItem>
            </Menu>
        </div>
    );
};

export default CompanyOptionsMenu;
