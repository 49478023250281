import { MoreVert, WarningAmber } from "@mui/icons-material";
import { Box, Checkbox, IconButton, Typography } from "@mui/material";
import React, { FC, MouseEvent, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import colors from "src/styles/colors.scss";
import {
    AllocatorJob,
    AllocatorReportsPageSize,
    ContextMenuPosition,
    CorrectedAddresses,
    FixMeLater,
    JOB_STATUS_TYPES,
} from "src/types";
import { DEFAULT_SEARCH_PARAMS } from "../../constant";
import ConfirmationDialog from "../ConfirmationDialog/ConfirmationDialog";
import { StyledJobCard, StyledJobCardContent, StyledTypography } from "./JobCard.styled";
import JobInfoMenu from "./components/JobInfoMenu/JobInfoMenu";
import JobStatus from "./components/JobStatus/JobStatus";
import { getFirstPathSegment } from "src/services/Utility";
import { useSnackbar } from "notistack";

interface JobCardProps {
    job: AllocatorJob;
}

const JobCard: FC<JobCardProps> = ({ job }) => {
    const [jobMenuPosition, setJobMenuPosition] =
        useState<ContextMenuPosition | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [, setSearchParams] = useSearchParams();
    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value
    );
    const jobList: AllocatorJob[] = useAppSelector(
        (state) => state?.[product?.productName]?.value?.jobs?.jobList
    );
    const selectedJob: AllocatorJob = useAppSelector(
        (state) => state?.[product?.productName]?.value?.jobs?.selectedJob
    );
    const correctedAddresses: CorrectedAddresses = useAppSelector(
        (state) => state?.[product?.productName]?.value?.correctedAddresses
    );
    const reportsPageSize: AllocatorReportsPageSize = useAppSelector(
        (state) => state?.[product?.productName]?.value?.reportsPageSize
    );
    const isJobCompleted = job?.status?.name !== JOB_STATUS_TYPES.FAILED && job?.status?.name !== JOB_STATUS_TYPES.PROCESSING;
    const checkedJob = jobList?.find(job => job.checked);
    const isCheckboxEnabled = 
        checkedJob?.stateAbbr === job?.stateAbbr && 
        checkedJob?.year === job?.year && 
        checkedJob?.quarter === job?.quarter && 
        checkedJob?.companyAbbreviation === job?.companyAbbreviation

    const dispatch = useAppDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const openJobInfoMenu = (event: MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();

        setJobMenuPosition(
            jobMenuPosition === null
                ? {
                      mouseX: event.clientX,
                      mouseY: event.clientY,
                  }
                : // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                  null
        );
    };

    const handleCloseJobInfoMenu = () => {
        setJobMenuPosition(null);
    };

    const updateSelectedJob = () => {
        handleClose();
        setSearchParams(
            `?${new URLSearchParams({
                jobId: job?.id?.toString(),
                report: isJobCompleted ? DEFAULT_SEARCH_PARAMS.summary : DEFAULT_SEARCH_PARAMS.report,
                page: DEFAULT_SEARCH_PARAMS.page,
                size: reportsPageSize[String(job.id)] ?? DEFAULT_SEARCH_PARAMS.size,
            })}`
        );
        dispatch(GlobalStateActions[product?.productName].setEmptyCorrectedAddresses());
        dispatch(GlobalStateActions[product?.productName].setEmptyReportsFilterState());
        dispatch(GlobalStateActions[product?.productName].setSelectedJob(job));
    };

    const handleJobClick = () => {
        if (job?.id === selectedJob?.id) {
            return;
        }

        if (Object.keys(correctedAddresses).length) {
            setOpen(true);
        } else {
            updateSelectedJob();
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCheckboxСhange = (value: boolean) => {
        dispatch(GlobalStateActions[product?.productName].setCheckedJob({...job, checked: value}));
    };

    const handleCheckboxСlick = (event: MouseEvent<HTMLElement>) => {
        if (checkedJob && !isCheckboxEnabled) {
            event.preventDefault();
            enqueueSnackbar("Selected jobs must belong to the same company, state, quarter and year to be exported together.", { variant: "error" })
        }
        event.stopPropagation();
    };

    const handleNavigate = (event: MouseEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();
        
        window.open(
            `${
                window.location.origin
            }/${getFirstPathSegment()}/warnings/${job?.warningId}`,
            "_blank"
        );
    };

    const actionStay = {
        text: "Stay on this page",
        action: handleClose,
    };

    const actionLeave = {
        text: "Leave",
        action: updateSelectedJob,
    };

    const fileContent = `${job?.stateAbbr}, ${job?.year}` +
        (job?.quarter && `, ${job?.quarter}`) +
        (job?.companyAbbreviation && `, ${job?.companyAbbreviation}`)

    return (
        <StyledJobCard
            variant="outlined"
            $isSelected={selectedJob?.id === job?.id}
            onContextMenu={openJobInfoMenu}
            data-testid="JobCardContainer"
        >
            <StyledJobCardContent $isDisabled={!!checkedJob && !isCheckboxEnabled} onClick={handleJobClick}>
                <Box>
                    <div className="job-card-top">
                        <JobStatus
                            jobStatus={job?.status}
                            allowedStatuses={job?.allowedStatuses}
                            jobId={job?.id}
                            progress={job?.progress || 0}
                        />
                        {
                            !!job?.warningId && (
                                <IconButton title="View Warning Log" onClick={handleNavigate}>
                                    <WarningAmber color="warning" />
                                </IconButton>
                            )
                        }
                    </div>
                    <StyledTypography
                        sx={{
                            fontWeight: "bold",
                            mb: 0.5,
                            mt: 0.5,
                        }}
                        title={job?.filename}
                    >
                        {job?.filename}
                    </StyledTypography>
                    <StyledTypography
                        color={colors.allocatorTextGreyColor}
                        title={fileContent}
                        
                    >
                        {fileContent}
                    </StyledTypography>
                    <Typography
                        sx={{ fontSize: 14 }}
                        color={colors.allocatorTextGreyColor}
                    >
                        {`Good matches: ${job?.goodMatches}`}
                    </Typography>
                </Box>
                <div className="job-card-actions">
                    <IconButton onClick={openJobInfoMenu}>
                        <MoreVert />
                    </IconButton>
                    <Checkbox
                        checked={!!job?.checked}
                        onChange={() => handleCheckboxСhange(!job?.checked)}
                        onClick={handleCheckboxСlick}
                    />
                </div>
            </StyledJobCardContent>
            <JobInfoMenu
                job={job}
                position={jobMenuPosition}
                handleCloseJobInfoMenu={handleCloseJobInfoMenu}
            />
            <ConfirmationDialog
                open={open}
                title={"You have unsaved changes. Leave the page?"}
                content={"If you leave the page all changes will be discarded."}
                actionPrimary={actionStay}
                actionSecondary={actionLeave}
                handleClose={handleClose}
            />
        </StyledJobCard>
    );
};

export default JobCard;
